<template>
  <div class="class">
    <van-nav-bar
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-button style="width: 100%" type="info" @click="openDia(1)"
      >添加分类</van-button
    >
    <div class="item" v-for="(v, i) in list" :key="i">
      <span>{{ v.className }}</span>
      <div class="btns">
        <van-button
          type="danger"
          style="margin-right: 8px"
          @click="delClassBtn(v.id)"
          >删除</van-button
        >
        <van-button type="primary" @click="openDia(2, v)">编辑</van-button>
      </div>
    </div>

    <van-dialog
      v-model="dia.show"
      :title="dia.title"
      show-cancel-button
      @confirm="confirmBtn"
    >
      <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
      <van-field v-model="dia.cont" label="分类名字" placeholder="请输入" />
    </van-dialog>
  </div>
</template>

<script>
import { getClass, addClass, delClass, updateClass } from "@/api/com";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      list: [],
      dia: {
        show: false,
        title: "添加",
        id: "",
        cont: "",
        type: "1",
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    openDia(type, cur) {
      if (type == 1) {
        this.dia.title = "添加";
      } else {
        this.dia.title = "编辑";
        this.dia.id = cur.id;
        this.dia.cont = cur.className;
      }
      this.dia.type = type;
      this.dia.show = true;
    },
    async confirmBtn() {
      if (this.dia.type == 1) {
        let res = await addClass({ className: this.dia.cont });
        if (res.code == 0) {
          Toast(res.msg);
          this.dia.cont = ''
          this.getDataList();
        }
      } else {
        let res = await updateClass({ className: this.dia.cont }, this.dia.id);
        if (res.code == 0) {
          Toast(res.msg);
            this.dia.cont = ''
          this.getDataList();
        }
      }
    },
    delClassBtn(id) {
      Dialog.confirm({
        title: "确认删除?",
      })
        .then(async () => {
          let res = await delClass(id);
          if (res.code == 0) {
            Toast("删除成功");
            this.getDataList();
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async getDataList() {
      let res = await getClass();
      this.list = res.data;
    },
    onClickLeft(){
        this.$router.push('/')
    }
  },
};
</script>

<style scoped>
.class {
  /* padding: 20px; */
}
.item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
}
</style>